"use client"

import { useState } from "react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip";
import { Icons } from "./ui/icons";
interface Props {
  value: string;
  tooltip: string;
  children: React.ReactNode;
}
export function CopyToClipboardToolTip({ value, children, tooltip }: Props) {
  const [clicked, setClicked] = useState(false)
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger onClick={() => {
          navigator.clipboard.writeText(value);
          setClicked(true)
          setTimeout(() => setClicked(false), 1000)
        }}>
          {clicked ? (
            <Icons.check className="w-4 h-4 text-green-600" />
          ) : children}
        </TooltipTrigger>
        <TooltipContent className="bg-background text-foreground border-input">
          <p>{tooltip}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
