"use client"

import { submitContract } from "@/actions/submit-contract";
import { Button } from "@/components/ui/button";
import { useUserOperation } from "@/providers/userop";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { useFormStatus } from "react-dom";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { Icons } from "./ui/icons";
import { Input } from "./ui/input";
import { toast } from "sonner";

export function VerifyContactDialog({ contract }: { contract?: string }) {
  const { chainId } = useUserOperation();
  const [value, setValue] = useState(contract ?? '')
  const [open, setOpen] = useState(false)
  const router = useRouter()
  const submitContractWithValues = submitContract.bind(null, { chainId: chainId ?? '', address: value })
  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogTrigger asChild>
        <Button size="sm" variant="secondary" className="self-start" onClick={() => setOpen(true)}>Verify Contract</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px]">
        <form action={async () => {
          const x = await submitContractWithValues()
          if (x.error) {
            toast.error("Contract verification failed. Please verify on Etherscan.")
            setOpen(false)
            return
          }
          router.refresh()
        }}>
          <DialogHeader className="mb-4">
            <DialogTitle>Verify Contract address</DialogTitle>
            <DialogDescription>
              Upload the contract address to verify on Etherscan
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 mb-4">
            <Input
              autoComplete="off"
              placeholder="Enter contract address"
              value={value} className="col-span-3"
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
          <DialogFooter>
            <SubmitButton />
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

function SubmitButton() {
  const { pending } = useFormStatus()
  return (
    <Button type="submit" aria-disabled={pending}>{pending ? <Icons.loader className="animate-spin w-4 h-4" /> : "Submit"}</Button>
  )
}
