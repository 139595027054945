"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { redirect } from "next/navigation";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { blockchainData } from "@/data/blockchain";
import { cn } from "@/lib/utils";
import { useUserOperation } from "@/providers/userop";
import { useRouter } from "next/navigation";
import posthog from "posthog-js";
import { useEffect } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command";
import { Icons } from "./icons";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Textarea } from "./textarea";
import { submitTrace } from "@/actions/submit-trace";
import { SubmitButton } from "./submit-button";
const formSchema = z.object({
  chainId: z.string(),
  blockNumber: z.string().optional(),
  sender: z.string(),
  nonce: z.string(),
  initCode: z.string(),
  callData: z.string(),
  callGasLimit: z.string(),
  verificationGasLimit: z.string(),
  preVerificationGas: z.string(),
  maxFeePerGas: z.string(),
  maxPriorityFeePerGas: z.string(),
  paymasterAndData: z.string(),
  signature: z.string(),
});

export function UserOpForm() {
  const { op, blockNumber, chainId } = useUserOperation();
  //const [loading, setLoading] = useState(false)
  const key = JSON.stringify({ ...op, chainId, blockNumber });
  const router = useRouter();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues:
    {
      blockNumber: blockNumber ?? undefined,
      chainId: chainId ?? undefined,
      nonce: op?.nonce ?? "",
      initCode: op?.initCode ?? "",
      callData: op?.callData ?? "",
      callGasLimit: op?.callGasLimit ?? "",
      verificationGasLimit: op?.verificationGasLimit ?? "",
      preVerificationGas: op?.preVerificationGas ?? "",
      maxFeePerGas: op?.maxFeePerGas ?? "",
      maxPriorityFeePerGas: op?.maxPriorityFeePerGas ?? "",
      paymasterAndData: op?.paymasterAndData ?? "",
      signature: op?.signature ?? "",
    },
  });



  const disabled = !form.formState.isDirty
  useEffect(() => {
    const defaultValues = { ...op, chainId, blockNumber: blockNumber ?? undefined }
    form.reset(defaultValues);
  }, [key]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    const { chainId: chainIdValue, blockNumber: blockNumberValue, ...userOperation } = values;
    if (disabled) {
      return;
    }
    const path = new URL(window.location.href);
    path.searchParams.set("chainId", chainIdValue);
    path.searchParams.set("method", "simulateHandleOp");
    if (blockNumberValue) path.searchParams.set("blockNumber", blockNumberValue);
    path.searchParams.set("userOperation", JSON.stringify(userOperation));
    router.refresh()
    window.location.href = path.toString();
    posthog.capture("trace-userop-button-clicked");
  }

  const values = form.getValues()
  const { chainId: chainIdValue, blockNumber: blockNumberValue, ...userOperation } = values;
  const submitTraceWithValues = submitTrace.bind(null, {
    chainId: chainIdValue, blockNumber: blockNumberValue, userOperation
  })
  return (
    <Form {...form}>
      <form
        //onSubmit={form.handleSubmit(onSubmit)}
        action={async () => {
          const { chainId: chainIdValue, blockNumber: blockNumberValue, ...userOperation } = values;
          const path = new URL(window.location.href);
          path.searchParams.set("chainId", chainIdValue);
          path.searchParams.set("method", "simulateHandleOp");
          if (blockNumberValue) path.searchParams.set("blockNumber", blockNumberValue);
          path.searchParams.set("userOperation", JSON.stringify(userOperation));
          router.refresh()
          router.push(path.toString())
          //redirect(path.toString())
        }}
        className="space-y-4 text-xs pb-4"
      >
        <div className="space-y-6">
          <FormField
            control={form.control}
            name="chainId"
            render={({ field }) => {
              return (
                <FormItem className="flex flex-col">
                  <FormLabel>Blockchain</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "justify-between",
                            !field.value && "text-muted-foreground"
                          )}
                        >
                          {field.value ? (
                            <div className="flex flex-row items-center">
                              <img
                                src={
                                  blockchainData.find(
                                    (blockchain) =>
                                      blockchain.chainId === field.value
                                  )?.icon
                                }
                                alt="Blockchain Icon"
                                className="h-4 w-4 mr-3"
                              />
                              {
                                blockchainData.find(
                                  (blockchain) =>
                                    blockchain.chainId === field.value
                                )?.label
                              }
                            </div>
                          ) : (
                            "Select blockchain"
                          )}
                          <Icons.caretSort className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="p-0">
                      <Command>
                        <CommandInput
                          placeholder="Search blockchain..."
                          className="h-9"
                        />
                        <CommandEmpty>No found.</CommandEmpty>
                        <CommandGroup>
                          {blockchainData.map((blockchain) => (
                            <CommandItem
                              value={blockchain.label}
                              key={blockchain.chainId}
                              onSelect={() => {
                                form.setValue("chainId", blockchain.chainId, { shouldDirty: true });
                              }}
                            >
                              <img
                                src={blockchain.icon}
                                alt="Blockchain Icon"
                                className="h-4 w-4 mr-3"
                              />
                              {blockchain.label}
                              <Icons.check
                                className={cn(
                                  "ml-auto h-4 w-4",
                                  blockchain.chainId === field.value
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="blockNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Block Number</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormDescription>
                  Leave blank to use the latest block number.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="sender"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Sender</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="nonce"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Nonce</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="initCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Init Code</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="callData"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Call Data</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="callGasLimit"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Call Gas Limit</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="verificationGasLimit"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Verification Gas Limit</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="preVerificationGas"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Pre-verification Gas</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="maxFeePerGas"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Max Fee Per Gas</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="maxPriorityFeePerGas"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Max Priority Fee/Gas</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="paymasterAndData"
            render={({ field }) => (
              <FormItem>
                <FormLabel>PaymasterAndData</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="signature"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Signature</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <SubmitButton disabled={disabled}>Trace UserOperation </SubmitButton>
      </form>
    </Form >
  );
}

