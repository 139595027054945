import { FormInput, Copy, ArrowRight, Loader2, Share, AlertCircle, AlertTriangle, ChevronDown, ChevronRight } from "lucide-react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons"

export const Icons = {
  arrowDown: ChevronDown,
  arrowRight: ChevronRight,
  pointRight: ArrowRight,
  exclamationTriangle: AlertTriangle,
  exclamationCircle: AlertCircle,
  share: Share,
  caretSort: CaretSortIcon,
  check: CheckIcon,
  loader: Loader2,
  copy: Copy,
  formInput: FormInput,
};
