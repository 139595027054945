export const blockchainData = [
  {
    label: "Ethereum",
    chainId: "0x1",
    icon: "/ethereum-logo.svg",
  },
  {
    label: "Sepolia",
    chainId: "0xaa36a7",
    icon: "/ethereum-logo.svg",
  },
  {
    label: "Polygon",
    chainId: "0x89",
    icon: "/polygon-logo.svg",
  },
  {
    label: "Polygon Mumbai",
    chainId: "0x13881",
    icon: "/polygon-logo.svg",
  },
  {
    label: "Arbitrum",
    chainId: "0xa4b1",
    icon: "/arbitrum-logo.svg",
  },
  {
    label: "Arbitrum Sepolia",
    chainId: "0x66eee",
    icon: "/arbitrum-logo.svg",
  },
  {
    label: "OP Mainnet",
    chainId: "0xa",
    icon: "/optimism-logo.svg",
  },
  {
    label: "OP Sepolia",
    chainId: "0xaa37dc",
    icon: "/optimism-logo.svg",
  },
  {
    label: "Base",
    chainId: "0x2105",
    icon: "/base-icon.svg",
  },
  {
    label: "Base Sepolia",
    chainId: "0x14a34",
    icon: "/base-icon.svg",
  },
  {
    label: "BSC Mainnet",
    chainId: "0x38",
    icon: "/bsc-logo.svg",
  },
  {
    label: "BSC Testnet",
    chainId: "0x61",
    icon: "/bsc-logo.svg",
  },
  {
    label: "Avalanche",
    chainId: "0xa86a",
    icon: "/avalanche-c-chain-logo.svg",
  },
  {
    label: "Avalanche Fuji",
    chainId: "0xa869",
    icon: "/avalanche-c-chain-logo.svg",
  },
] as const;
