import(/* webpackMode: "eager" */ "/Users/hazim-j/Desktop/userop-debugger-frontend/node_modules/.pnpm/next@14.0.4_@babel+core@7.23.6_react-dom@18.0.0_react@18.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/components/ui/textarea.tsx\",\"import\":\"IBM_Plex_Mono\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"style\":[\"normal\"]}],\"variableName\":\"font\"}");
import(/* webpackMode: "eager" */ "/Users/hazim-j/Desktop/userop-debugger-frontend/src/app/_components/share-button.tsx");
import(/* webpackMode: "eager" */ "/Users/hazim-j/Desktop/userop-debugger-frontend/src/components/copy-tooltip.tsx");
import(/* webpackMode: "eager" */ "/Users/hazim-j/Desktop/userop-debugger-frontend/src/components/ui/accordion.tsx");
import(/* webpackMode: "eager" */ "/Users/hazim-j/Desktop/userop-debugger-frontend/src/components/ui/drawer.tsx");
import(/* webpackMode: "eager" */ "/Users/hazim-j/Desktop/userop-debugger-frontend/src/components/ui/hover-card.tsx");
import(/* webpackMode: "eager" */ "/Users/hazim-j/Desktop/userop-debugger-frontend/src/components/ui/label.tsx");
import(/* webpackMode: "eager" */ "/Users/hazim-j/Desktop/userop-debugger-frontend/src/components/ui/node.tsx");
import(/* webpackMode: "eager" */ "/Users/hazim-j/Desktop/userop-debugger-frontend/src/components/ui/op-form.tsx");
import(/* webpackMode: "eager" */ "/Users/hazim-j/Desktop/userop-debugger-frontend/src/components/ui/paste-userop-input.tsx");
import(/* webpackMode: "eager" */ "/Users/hazim-j/Desktop/userop-debugger-frontend/src/components/ui/progress.tsx");
import(/* webpackMode: "eager" */ "/Users/hazim-j/Desktop/userop-debugger-frontend/src/components/verify-contract-dialog.tsx");
import(/* webpackMode: "eager" */ "/Users/hazim-j/Desktop/userop-debugger-frontend/src/providers/userop.tsx")