"use client";

import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import React from "react";
import posthog from "posthog-js";
import { cn } from "@/lib/utils";

export function ShareButton({ className }: { className?: string }) {
  const [copied, setCopied] = React.useState(false);

  return (
    <Button
      variant="secondary"
      className={cn("items-center", className)}
      onClick={() => {
        navigator.clipboard.writeText(window.location.href);
        setCopied(true);
        posthog.capture("share-button-clicked");
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      }}
    >
      {copied ? "Copied to Clipboard" : "Share"}
      {copied ? (
        <Icons.check className="ml-1 2-3 h3" />
      ) : (
        <Icons.share className="ml-1 w-3 h-3" />
      )}
    </Button>
  );
}
