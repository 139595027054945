"use client";
import { useUserOperation } from "@/providers/userop";
import { z } from "zod";
import { Input } from "./input";
import { UserOperationSchema } from "@/core/userop";
import { toast } from "sonner";
import { useState } from "react";
import { Textarea } from "./textarea";

export function PasteUserOp() {
  const { setOp } = useUserOperation();

  const handleOnChange = (value: string) => {
    try {
      let cleaned = ""
      cleaned = value.replace(/'/g, '"');
      cleaned.replace(/(\w+):/g, '"$1":');

      const json = JSON.parse(cleaned);
      const op = UserOperationSchema.parse(json);

      setOp(op);
      toast.success("UserOperation pasted successfully");
    } catch (e: any) {
      console.log(e);
      if (e instanceof z.ZodError) {
        toast.error(<p>{JSON.stringify(e.issues, null, 2)}</p>);
      } else {
        toast.error(<div><h1> Paste Error</h1><p>{e?.message}</p></div>);
      }
    }
  };


  return (
    <Textarea
      autoComplete="no-password"
      placeholder="Paste UserOperation JSON..."
      name="paste"
      onChange={(e) => handleOnChange(e.target.value)}
    />
  );
}
