"use client";

import { cn } from "@/lib/utils";
import { type VariantProps, cva } from "class-variance-authority";
import { Icons } from "./icons";
import React from "react";

const variants = cva("", {
  variants: {
    indent: {
      4: "pl-4",
      6: "pl-6",
      8: "pl-8",
      12: "pl-12",
      16: "pl-16",
      20: "pl-20",
      24: "pl-24",
      28: "pl-28",
      32: "pl-32",
      36: "pl-36",
      40: "pl-40",
    },
  },
  defaultVariants: {
    indent: 4,
  },
});

export interface RootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  expanded?: boolean;
}

export function Node({ children, expanded, className }: RootProps) {
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child as React.ReactElement, { expanded });
  });

  return (
    <div className={cn("border-b items-center", className)}>
      {childrenWithProps}
    </div>
  );
}

export interface ItemProps
  extends React.HTMLAttributes<HTMLDivElement>,
    RootProps,
    VariantProps<typeof variants> {}

export function NodeItem({ children, indent, expanded, className }: ItemProps) {
  if (React.Children.toArray(children).length !== 2) {
    throw new Error("Node.Item must have 2 children");
  }

  const [open, setOpen] = React.useState(expanded ?? false);
  const header = React.Children.toArray(children)[0];
  const content = React.Children.toArray(children)[1];

  const Icon = open ? Icons.arrowDown : Icons.arrowRight;
  return (
    <div
      tabIndex={0}
      className={cn("w-full hover:bg-muted/30 focus:bg-muted/30", className)}
    >
      <div
        className={cn(
          "flex items-center space-x-2 py-1",
          open && "border-b",
          variants({ indent })
        )}
        onClick={() => setOpen(!open)}
      >
        <Icon className="w-4 h-4 text-muted-foreground" />
        <div>{header}</div>
      </div>
      {open && (
        <div className={cn("px-3 py-6", variants({ indent }))}>
          <div>{content}</div>
        </div>
      )}
    </div>
  );
}

export interface TriggerProps {
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
}

export function NodeTrigger({ children, className, onClick }: TriggerProps) {
  return (
    <span
      className={cn("cursor-pointer", className)}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {children}
    </span>
  );
}
export function NodeContent({ children, className }: RootProps) {
  return <span className={cn("", className)}>{children}</span>;
}
