import { useFormStatus } from "react-dom"
import { Button } from "./button"
import { Icons } from "./icons"

interface Props {
  children: React.ReactNode
  className?: string
  disabled?: boolean
}
export function SubmitButton({ children, className, disabled }: Props) {
  const { pending } = useFormStatus()
  return (
    <Button
      type="submit"
      className={className}
      disabled={pending || disabled}
      aria-disabled={pending}>
      {pending ?
        <Icons.loader className="animate-spin w-4 h-4" /> : children}</Button>
  )
}
